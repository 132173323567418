// Import necessary functions and components from other files
import { useState, useEffect } from 'react'; // Import hooks from React
import { search } from './services/hubspot'; // Import a search function from hubspot services
import { useLocation} from 'react-router-dom'; // Use React Router's useLocation hook
import { DisplayData, displayInvalidInput, displayNoData } from './components/dataField'; // Import functions to display data or an error message
import Header from './components/header'; // Import a header component

// Create a functional component named 'App'
const App = () => {
// Declare state variables
const [curInput, setCurInput] = useState('') // Input value
const [searchDone, setSearchDone] = useState(false) // Has search been done?
const [searchData, setSearchData] = useState(null) // The data received from search
const [invalidInput, setInvalidInput] = useState(false) // Is the input invalid?
const [params, setParams] = useState('') // Query parameter value
const [loading, setLoading] = useState(false) // Is data loading?
const [searchByQparams, setSearchByQparams] = useState(false) // Is search done by query params?

// Get current location
const location = useLocation()

// Function that prevents page reload on form submission and starts the search
const submit=async (event) => {
  event.preventDefault();
  findObject(curInput)
}

// Function that searches for an object with the given id, or sets 'invalidInput' to true
const findObject =  async(id) => {
  setLoading(true)
  if(/\bS{1}[0-9]{9,10}\b/.test(id) || /\bP{1}[0-9]{9,11}\b/.test(id)){
    const data = await search(id)
    setSearchData(data)
    setInvalidInput(false)
  } else{
    setSearchData(null)
    setInvalidInput(true)
  }
  setSearchDone(true)
  setLoading(false)
}

// useEffect hook that gets the id from the query params and uses it for searching
useEffect(()=>{
  const queryParams = new URLSearchParams(location.search)
  const singleValue = queryParams.get('id')
  if(singleValue) {
    setCurInput(singleValue)
    setParams(singleValue)
    findObject(singleValue)
    setSearchByQparams(true)
  }
},[location])

// Function that handles changes in the input field
const handleInputChange = (event) => {
  event.preventDefault();
  setParams(event.target.value)
  setCurInput(event.target.value)
}

// The returned JSX (the actual UI that will be rendered)
return(
  <div className='bg-light-orange h-screen font-light w-full p-1'>
    {searchByQparams && <Header/> }
      <div className='bg-white h-[300px] shadow rounded '>
        <div className='pt-1'>
          <form onSubmit={submit} className='p-2 pl-3' >
            <div className='text-[0.6rem] md:text-xs'>Syötä pyörätunnus</div>
            <div className='flex pt-1 pb-0.5'>
              <input placeholder='PXXXXXX' type="text" className='bg-gray-200 rounded-lg p-1 text-xs md:text-sm w-5/6 hover:outline-dark-orange focus:outline-dark-orange' onChange={handleInputChange} value={params}/>
            </div>
            <div className='pt-2'>
              <button type='submit' className='text-xs md:text-sm py-1 px-1.5 bg-dark-orange rounded-md text-white hover:bg-hover-orange'> Tarkista tilauksen tila</button>
            </div>
          </form>
        </div>
        <div className='w-full'>
          {loading ? <div className="animate-spin inline-block w-8 h-8 border-[3px] border-dark-orange border-t-transparent text-dark-orange rounded-full" role="status" aria-label="loading"/>
          :(searchDone ? (invalidInput ? displayInvalidInput() : (searchData ? <DisplayData data={searchData}/> : displayNoData()))
          : <div></div>)
          }
        </div>
      </div>
  </div>
)
};
export default App