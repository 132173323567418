// Import the Axios library for making HTTP requests
import axios from 'axios';

// Set the base URL for the API endpoint to be called
const baseUrl = 'https://europe-north1-gbb-gcp.cloudfunctions.net/hubspot-get-order-status';

// Define a function called "search" that takes a request parameter id
// and returns a Promise that will resolve to the response data or null if an error occurs.
export const search = async (id) => {
  try {
    // Send an HTTP POST request to the specified URL (baseUrl) with the request data as the payload.
    // "await" indicates that the function will pause execution until the request is complete.
    const response = await axios.post(baseUrl, { id: id });

    // If the request is successful, return the response data (presumably containing order status information).
    return response.data;
  } catch (err) {
    // If an error occurs during the request (e.g., network error, server error), catch it here.
    // In this case, return null to indicate that the search was unsuccessful.
    return null;
  }
};
