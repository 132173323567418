export const DisplayData  =({data}) => {
  return(
    <div className="w-full text-xs pl-3 p-2 sm:flex sm:gap-2 md:text-sm">
      <div className="w-full border-b py-1 sm:border-b-0">
        <div className="font-bold">Tilaus:</div>
        <div className="pl-0.5">{data.id}</div>
      </div>
      <div className="w-full border-b py-1 sm:border-b-0" >
        <div className="font-bold">Pyörä:</div>
        <div className="pl-0.5">{data.model}</div>
      </div>
      <div className="w-full border-b py-1 sm:border-b-0" >
        <div className="font-bold">Tila:</div>
        <div className="pl-0.5">{data.status}</div>
      </div>
      <div className="w-full py-1" >
        <div className="font-bold">Arvioitu toimituspäivä:</div>
        <div className="pl-0.5">{data.delivery_date}</div>
      </div>
    </div>
  )
}

export const displayInvalidInput = () => (
  <div className="w-5/6 pl-3 pt-3">
    <div className="bg-red-100 border-l-4 border-red-500 text-red-600 p-4 shadow rounded-lg" role="alert">
    <p className="font-bold">Syöttämäsi tunnus on virheellinen.</p>
    <p>Tarkista, että olet kirjoittanut tunnuksen oikein ja se on oikeassa muodossa.</p>
    </div>  
  </div>
)

export const displayNoData = () => (
  <div className="w-5/6 pl-3 pt-3">
    <div className="bg-red-100 border-l-4 border-red-500 text-red-600 p-4 shadow rounded-lg" role="alert">
    <p className="font-bold">Tilauksen haku epäonnistui.</p>
    <p>Yritä hetken päästä uudelleen, jos haku ei edelleenkään toimi, ota yhteyttä <a href="mailto:info@gobybike.fi" className="font-bold hover:text-red-700">info@gobybike.fi</a>.</p>
  </div>  
</div>

)