const Header = () => {
  return(
    <div className="p-3">
      <a href="https://www.gobybike.fi/">
        <img src="https://www.gobybike.fi/logo.svg" alt="logo"/>
      </a>
    </div>
  )
}

export default Header
